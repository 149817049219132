.nav-container {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: var(--tertiary-color);

  height: 75px;
  padding: 0 50px;

  justify-content: space-between;
}

.nav-container .contact_button {
  min-width: 4rem;
  padding: 8px;
  margin: 0;

  font-size: 16px;
  color: var(--tertiary-color);
  background-color: var(--other-color2);

  align-self: center;
  text-align: center;

  opacity: 0;
  -webkit-animation: fadeDown 0.5s 0.75s ease-in forwards;
  -moz-animation: fadeDown 0.5s 0.75s ease-in forwards;
  -o-animation: fadeDown 0.5s 0.75s ease-in forwards;
  animation: fadeDown 0.5s 0.75s ease-in forwards;
}

.nav-container ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;

  align-self: center;
}

.nav-container ul > li {
  float: right;
}

.nav-container ul > li > a {
  display: block;
  color: white;
  text-align: center;
  padding: 04px 16px;
  text-decoration: none;
}

li a span {
  color: var(--other-color2);
}

li a:hover {
  background-color: #111;
}

.active {
  border-bottom: 2px solid var(--other-color2);
}

.mobile {
  background-color: transparent;
}

.largeScreen {
  display: none;
}

.largeScreen ul li {
  opacity: 0;
  animation: fadeDown 0.3s ease-in forwards;
}

.mobile {
  padding: 16px;
  height: auto;
}

.menuButton {
  position: relative;
  font-size: xx-large;
  color: white;

  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;

  /* opacity: 0; */
  /* -webkit-animation: fadeDown 0.5s 0.75s ease-in forwards;
  -moz-animation: fadeDown 0.5s 0.75s ease-in forwards;
  -o-animation: fadeDown 0.5s 0.75s ease-in forwards;
  animation: fadeDown 0.5s 0.75s ease-in forwards; */

  -webkit-transition: opacity 0.3s ease-in;
  -moz-transition: opacity 0.3s ease-in;
  -o-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in;
}

.fade-out-trans {
  opacity: 0;
}

@media only screen and (min-width: 768px) {
  .mobile {
    display: none;
  }
  .largeScreen {
    display: flex;
    font-size: 18px;
  }
}

@-webkit-keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-moz-keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-o-keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.largeScreen ul > li:nth-child(4) {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s;
}
.largeScreen ul > li:nth-child(3) {
  -webkit-animation-delay: 1.25s;
  -moz-animation-delay: 1.25s;
  -o-animation-delay: 1.25s;
  animation-delay: 1.25s;
}
.largeScreen ul > li:nth-child(2) {
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  -o-animation-delay: 1.5s;
  animation-delay: 1.5s;
}
.largeScreen ul > li:nth-child(1) {
  -webkit-animation-delay: 1.75s;
  -moz-animation-delay: 1.75s;
  -o-animation-delay: 1.75s;
  animation-delay: 1.75s;
}
