.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.overlay .page-link {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay .overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
  text-decoration-line: underline;
  text-decoration-color: var(--other-color2);
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
  color: var(--other-color2);
  text-decoration: none;
}

.overlay-content .social-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.overlay-content .social-container a {
  text-decoration: none;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

@media only screen and (min-width: 768px) {
  .overlay {
    display: none;
  }
}
