.other-container {
  min-height: 100vh;
}

.projects-grid {
  display: grid;
  grid-template-columns: 1fr;
  flex-wrap: wrap;
  row-gap: 32px;
  column-gap: 8px;

  color: white;
  text-align: start;
  word-wrap: break-word;

  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.other-project-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 75%;
  height: 300px;

  padding: 16px;
  border-radius: 5px;

  background-color: var(--tertiary-color);
  border: 2px solid var(--other-color2);

  justify-self: center;
}

.other-project-container:hover {
  transform: translateY(-2%);
}

.other-project-container i {
  font-size: 54px;
}

.code-container {
  display: flex;
  flex-wrap: wrap;
}

.code-container label {
  color: var(--other-color2);
  margin-right: 16px;
}

.link-container {
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  padding: 16px;

  bottom: 0;
  right: 0;
}

.link-container a:first-child {
  margin-left: 16px;
}

.link-container a {
  color: white;
}

.link-container i {
  color: white;
  font-size: 24px;
}

.link-container i:hover {
  color: var(--other-color2);
  cursor: pointer;
}

.more-button-container {
  margin-top: 100px;
}

@media only screen and (min-width: 768px) {
  .projects-grid {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 16px;

    justify-content: flex-start;
  }

  .other-project-container {
    width: 250px;
  }
}

@media only screen and (min-width: 1050px) {
  .projects-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
