.header {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 50vh;
  height: auto;
  padding: 40px 20px 40px 20px;
  background-color: var(--other-color);
}

.header > div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-self: center;

  -webkit-animation: fadeIn 0.75s 1 0s ease-in;
  -moz-animation: fadeIn 0.75s 1 0s ease-in;
  -o-animation: fadeIn 0.75s 1 0s ease-in;
  animation: fadeIn 0.75s 1 0s ease-in;
}

.header .header-text-1 {
  font-size: 18px;
  font-weight: bold;
  color: var(--main-color);
  margin: 0;
}

.header .header-text-2 {
  color: white;
  font-size: 50px;
  font-weight: bold;
  margin: 0 0 0 0;
}

.header .header-text-3 {
  color: grey;
  font-size: 30px;
  font-weight: bold;
  margin: 0 0 0 0;
}

.header .header-text-4 {
  color: lightgray;
  font-size: 16px;
  font-weight: bold;
  margin: 8 0 0 0;
  text-align: start;
  max-width: 500px;
}

.header .contact_button {
  width: 6rem;
  align-self: flex-start;
  text-align: center;

  margin-top: 0;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-20%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-20%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-20%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-20%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media only screen and (min-width: 768px) {
  .header {
    padding: 20px;
    height: 50vh;
  }

  .header .header-text-1 {
    font-size: 18px;
  }

  .header .header-text-2 {
    font-size: 80px;
  }

  .header .header-text-3 {
    font-size: 40px;
  }

  .header .header-text-4 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 900px) {
  .header {
    padding: 80px;
  }

  .header .header-text-4 {
    font-size: 20px;
  }
}
