.container {
  width: 100%;
  height: auto;
  min-height: 100vh;
  align-content: center;
}

.project-container {
  position: relative;
  width: 100%;
  min-height: 500px;

  align-content: center;
  justify-content: center;

  margin-bottom: 50px;
}

.project-container img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-filter {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--other-color);
  opacity: 0.75;
}

.text-container {
  position: absolute;
  top: 8px;
  left: 0;
  bottom: 8px;
  right: 0;
  width: 100%;
  min-height: 95%;
  color: white;
}

.test1 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
}

.right {
  text-align: right;
}

.text-container h2 {
  margin-top: 0;
  padding-right: 16px;
}

.text-container p {
  font-size: 18px;
  padding: 16px;
  margin-bottom: 0;
  /* background-color: rgba(1, 1, 1, 0.75); */
}

.title-container > div {
  margin: 8px 0px;
  padding-right: 16px;
}

.title-container a {
  color: white;
  font-size: 25px;
  margin-left: 24px;
}

.title-container a:hover {
  color: var(--other-color2);
  transition: 0.5s ease all;

  cursor: pointer;
}

.code-label-container {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

.code-label-container label {
  padding: 4px;
  margin-left: 14px;
  background-color: var(--tertiary-color);
  border-radius: 10px;

  flex-grow: 0;
  flex-shrink: 0;
  margin: 5px;
}

@media only screen and (min-width: 600px) {
  .image-filter {
    display: none;
  }

  .project-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 300px;

    align-content: center;
    justify-content: center;
  }

  .project-container img {
    position: relative;
    width: 100%;
    height: 300px;
    max-height: 500px;
    align-self: center;
  }

  .text-container {
    display: block;
    position: relative;
  }

  .text-container p {
    background-color: var(--secondary-color);
    border-radius: 10px;
    transform: translateX(-20px);
    margin-bottom: 16px;
  }

  .title-container h2 {
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .title-container a {
    font-size: 20px;
    margin-left: 8px;
  }

  .title-container > div {
    padding-right: 16px;
  }

  .right {
    text-align: right;
    /* transform: translateX(-10%); */
  }

  .code-label-container {
    padding-right: 20px;
  }

  .code-label-container label {
    background-color: VAR(--other-color);
  }
}

@media only screen and (min-width: 768px) {
  .project-container {
    grid-template-columns: 1fr 1fr;
  }

  .project-container img {
    width: 100%;
    height: 300px;
    max-height: 500px;
    align-self: center;
  }

  .text-container {
    position: relative;
  }

  .text-container h2 {
    font-size: 20px;
    margin: 0;
  }

  .text-container p {
    font-size: 16px;
    background-color: var(--secondary-color);
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }

  .title-container > div {
    padding-left: 8px;
    align-self: center;
  }

  .title-container a {
    font-size: 22px;
    margin-left: 8px;
  }
}

@media only screen and (min-width: 992px) {
  .project-container {
    grid-template-columns: 450px 450px;
  }

  .project-container img {
    width: 450px;
    height: 250px;
  }

  .text-container h2 {
    font-size: 28px;
  }

  .text-container p {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1275px) {
  .project-container {
    grid-template-columns: 1fr 1fr;
  }

  .project-container img {
    width: 100%;
    max-width: 650px;
    height: auto;
    max-height: 400px;

    justify-self: flex-end;
  }

  .text-container h2 {
    font-size: 32px;
  }
  .text-container a {
    font-size: 28px;
  }
  .text-container p {
    font-size: 20px;
  }

  .test1 {
    width: auto;
    max-width: 700px;
  }

  .code-label-container label {
    font-size: 18px;
  }
}
