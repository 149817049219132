.heading {
  display: flex;
  align-items: center;
  padding: 0px 50px;
}

.heading h3 {
  overflow: hidden;
  color: white;
}

.heading h3 span {
  color: var(--other-color2);
}

.line {
  border-top: 1.25px solid white;
  flex-grow: 1;
  margin: 0 10px;
}
