.about-container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 8px;
  min-height: 100vh;
}

.headshot-image {
  position: relative;
  justify-self: center;
  align-self: flex-end;
  width: 250px;
  height: auto;
  border-radius: 50%;
  border: 5px solid var(--other-color2);
  cursor: pointer;
  transition: 0.25s ease-in;
}

.headshot-image:hover {
  filter: contrast(50%) sepia(50%);
}

.line {
  border-top: 1.25px solid white;
  flex-grow: 1;
  margin: 0 10px;
}

.info-container {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: flex-start;

  max-width: 700px;
}

.info-container p {
  color: white;
  text-align: start;
}

.info-container p > span {
  color: var(--other-color2);
  font-weight: bold;
}

.tech-list {
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr;

  color: white;
  padding: 0;
}

.tech-item {
  position: relative;
}
.tech-item::before {
  content: "\2666";

  position: absolute;
  left: 0;

  color: var(--other-color2);
}

@media only screen and (min-width: 768px) {
  .about-container {
    grid-template-columns: 1fr 1fr;
  }
  .headshot-image {
    justify-self: center;
    align-self: center;

    width: 325px;
    height: auto;
  }

  .info-container {
    justify-self: center;
    align-self: center;
  }

  .info-container p {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1275px) {
  .headshot-image {
    width: 100%;
    max-width: 450px;
    height: auto;
  }
}
