.body {
  color: var(--main-color);
}
.App {
  display: block;
  min-height: 100vh;

  text-align: center;
  background-color: var(--tertiary-color);

  padding: 40px;
}

:root {
  --main-color: rgba(117, 221, 221, 1);
  --secondary-color: rgba(80, 137, 145, 1);
  --tertiary-color: rgba(23, 42, 58, 1);

  --other-color: rgba(0, 67, 70, 1);
  --other-color2: rgba(9, 188, 138, 1);
}

html {
  scroll-behavior: smooth;
}

@media only screen and (min-width: 768px) {
  .App {
    padding: 80px;
  }
}

@-webkit-keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-moz-keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-o-keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
