.contact_container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 100vh;
  height: auto;
  margin-top: 100px;
}

.contact_content {
  display: flex;
  flex-direction: column;

  row-gap: 10px;
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 0 0;

  color: var(--main-color);
}

.contact_content h4 {
  font-size: 24px;
  color: white;
}

.contact_content h4 > span {
  color: var(--other-color2);
}

.contact_content h2 {
  margin: 0;
}

.contact_content p {
  max-width: 600px;
  font-size: 16px;
  color: white;
  margin-top: 30px;
  margin-bottom: 30px;

  text-align: center;
  align-self: center;
}

.contact_button {
  min-width: 8rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  color: var(--other-color2);
  font-weight: bold;
  text-decoration: none;

  padding: 16px 16px 16px 16px;
  border-radius: 5px;

  border: 2px solid var(--other-color2);
  background-color: transparent;

  cursor: pointer;

  align-self: center;

  margin: 30px 0 30px 0;
}

.contact_button span {
  position: relative;
  transition: 0.4s ease-in-out;
}

.contact_button span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.contact_button:hover span {
  padding-right: 16px;
}

.contact_button:hover span::after {
  opacity: 1;
  right: 0;
}

.social-container {
  margin-top: 50px;
}

.social-container .fa {
  padding: 20px;
  font-size: 25px;
  width: 25px;
  text-align: center;
  text-decoration: none;
  margin: 5px 8px;
}

.fa:hover {
  opacity: 0.7;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}

.social-container .fa-github {
  color: white;
  background: #24292e;
}

.fa-instagram {
  background: #833ab4;
  color: white;
}

.fa-address-book {
  background: #b53a00;
  color: white;
}
